import React from 'react';

import styles from './section.module.css';

import Heading from '@components/Heading';

export const SectionContainer = ({
  children,
  isGrey,
  align,
  moreStyles,
  containerStyles,
}) => (
  <div
    className={`fade fade-0 z-10 relative ${
      isGrey ? 'bg-gray-0' : 'white dark:bg-cobalt-100'
    }  ${moreStyles ? styles.sectionContainer : ''}`}
    style={containerStyles}
  >
    <section
      className={`container mx-auto flex flex-col pb-20 pt-32 px-6 ${
        align === 'left' ? 'items-start' : 'items-center'
      }`}
      style={moreStyles}
    >
      {children}
    </section>
  </div>
);

export const SectionHeading = ({ text, id, moreStyles, isH1 }) => {
  return (
    <Heading
      Tag={isH1 ? 'h1' : 'h2'}
      size="lg"
      className="text-center"
      customStyle={moreStyles}
    >
      <div id={id} style={{ position: 'absolute', top: '-100px' }} />
      {text}
    </Heading>
  );
};

export const SectionSubheading = ({ text }) => (
  <p className={`fade fade-2 z-10 font-inter ${styles.subheading}`}>{text}</p>
);
