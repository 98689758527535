import Link from 'next/link';
import React from 'react';

import CustomHead from '../components/CustomHead';
import Flash from '../components/Flash';
import Footer from '../components/FooterNew';
import Header from '../components/HeaderNew';
import { SectionContainer } from '../components/landing/sectionComponents';

const Svg = () => (
  <svg
    width="269"
    height="105"
    viewBox="0 0 269 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.3739 103.542C52.399 103.542 51.5704 103.201 50.888 102.521C50.2056 101.84 49.8644 101.014 49.8644 100.042V81.375H3.50952C2.53465 81.375 1.70601 81.0347 1.02361 80.3542C0.341203 79.6736 0 78.8472 0 77.875V67.9583C0 66.4028 0.536176 64.9445 1.60853 63.5833L44.7463 3.9375C45.2338 3.15972 45.9649 2.57639 46.9398 2.1875C47.9146 1.70139 48.987 1.45833 50.1568 1.45833H66.0959C67.1682 1.45833 67.9969 1.79861 68.5818 2.47917C69.2642 3.0625 69.6054 3.88889 69.6054 4.95833V64.1667H82.6198C83.6922 64.1667 84.5696 64.507 85.252 65.1875C85.9344 65.7708 86.2756 66.5972 86.2756 67.6667V77.875C86.2756 78.8472 85.9344 79.6736 85.252 80.3542C84.5696 81.0347 83.7409 81.375 82.7661 81.375H69.6054V100.042C69.6054 101.014 69.2642 101.84 68.5818 102.521C67.9969 103.201 67.1682 103.542 66.0959 103.542H53.3739ZM50.3031 64.75V22.6042L20.3259 64.75H50.3031Z"
      fill="url(#svg-404-paint0_linear)"
    />
    <path
      d="M134.582 105C121.616 105 111.916 101.403 105.482 94.2083C99.1453 87.0139 95.6846 77.5833 95.0996 65.9167C95.0022 63.2917 94.9534 58.8194 94.9534 52.5C94.9534 46.0833 95.0022 41.5139 95.0996 38.7917C95.5871 27.3194 99.0966 17.9861 105.628 10.7917C112.16 3.59722 121.811 0 134.582 0C147.45 0 157.15 3.59722 163.681 10.7917C170.213 17.9861 173.674 27.3194 174.064 38.7917C174.259 41.5139 174.356 46.0833 174.356 52.5C174.356 58.8194 174.259 63.2917 174.064 65.9167C173.576 77.5833 170.116 87.0139 163.681 94.2083C157.345 101.403 147.645 105 134.582 105ZM134.582 88.2292C140.723 88.2292 145.305 86.2361 148.327 82.25C151.349 78.1667 152.958 72.4792 153.153 65.1875C153.348 59.5486 153.445 55.2708 153.445 52.3542C153.445 49.1458 153.348 44.9167 153.153 39.6667C152.958 32.4722 151.349 26.8333 148.327 22.75C145.305 18.6667 140.723 16.625 134.582 16.625C128.538 16.625 123.956 18.6667 120.836 22.75C117.814 26.8333 116.205 32.4722 116.01 39.6667C115.913 42.2917 115.864 46.5208 115.864 52.3542C115.864 58.0903 115.913 62.3681 116.01 65.1875C116.205 72.4792 117.814 78.1667 120.836 82.25C123.956 86.2361 128.538 88.2292 134.582 88.2292Z"
      fill="url(#svg-404-paint1_linear)"
    />
    <path
      d="M236.098 103.542C235.123 103.542 234.295 103.201 233.612 102.521C232.93 101.84 232.589 101.014 232.589 100.042V81.375H186.234C185.259 81.375 184.43 81.0347 183.748 80.3542C183.066 79.6736 182.724 78.8472 182.724 77.875V67.9583C182.724 66.4028 183.261 64.9445 184.333 63.5833L227.471 3.9375C227.958 3.15972 228.689 2.57639 229.664 2.1875C230.639 1.70139 231.711 1.45833 232.881 1.45833H248.82C249.893 1.45833 250.721 1.79861 251.306 2.47917C251.989 3.0625 252.33 3.88889 252.33 4.95833V64.1667H265.344C266.417 64.1667 267.294 64.507 267.976 65.1875C268.659 65.7708 269 66.5972 269 67.6667V77.875C269 78.8472 268.659 79.6736 267.976 80.3542C267.294 81.0347 266.465 81.375 265.49 81.375H252.33V100.042C252.33 101.014 251.989 101.84 251.306 102.521C250.721 103.201 249.893 103.542 248.82 103.542H236.098ZM233.027 64.75V22.6042L203.05 64.75H233.027Z"
      fill="url(#svg-404-paint2_linear)"
    />
    <defs>
      <linearGradient
        id="svg-404-paint0_linear"
        x1="19.5552"
        y1="10.9099"
        x2="71.7141"
        y2="132.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC8D39" />
        <stop offset="1" stopColor="#FF6C81" />
      </linearGradient>
      <linearGradient
        id="svg-404-paint1_linear"
        x1="19.5552"
        y1="10.9099"
        x2="71.7141"
        y2="132.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC8D39" />
        <stop offset="1" stopColor="#FF6C81" />
      </linearGradient>
      <linearGradient
        id="svg-404-paint2_linear"
        x1="19.5552"
        y1="10.9099"
        x2="71.7141"
        y2="132.342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC8D39" />
        <stop offset="1" stopColor="#FF6C81" />
      </linearGradient>
    </defs>
  </svg>
);

const Home = () => {
  return (
    <>
      <CustomHead />
      <main>
        <Flash />
        <Header />
        <SectionContainer
          styles={{
            minHeight: 'calc(100vh - 80px)',
            justifyContent: 'center',
          }}
        >
          <Svg />
          <h1 className="text-center text-black tracking-tightest text-4xl md:text-6xl font-medium mt-6">
            Page not found
          </h1>
          <p className="text-center mt-2 md:text-lg">
            Something went wrong, there's nothing here.
          </p>
          <Link href="/">
            <a className="mt-12 bg-primary text-white font-inter font-semibold py-4 px-5 rounded-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:opacity-80 transition duration-200 ease block text-center cursor-pointer leading-none shadow-sm">
              Back home
            </a>
          </Link>
        </SectionContainer>
      </main>
      <Footer />
    </>
  );
};

export default Home;
